import React from "react";
import theme from "theme";
import { Theme, Link, Image, Section, Text, Box } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override, StackItem, Stack } from "@quarkly/components";
import * as Components from "components";
import { MdMenu } from "react-icons/md";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"404"} />
		<Helmet>
			<title>
				Dataleaks.us
			</title>
			<meta name={"description"} content={"A non-profit organization that watches dataleak events around the world, alerting those affected and educating people to protest their privacy online"} />
			<link rel={"shortcut icon"} href={"https://i.imgur.com/eGfhJiA.png"} type={"image/x-icon"} />
		</Helmet>
		<Section padding="16px 0 16px 0" quarkly-title="Header" align-items="center" justify-content="center">
			<Override
				slot="SectionContent"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				md-grid-template-columns="repeat(2, 1fr)"
				margin="0px 32px 0px 32px"
				width="100%"
				md-margin="0px 16px 0px 16px"
			/>
			<Link href="/" position="relative" transition="opacity 200ms ease" quarkly-title="Link">
				<Image
					src="https://uploads.quarkly.io/64d09596098f07001999d2f7/images/cuted_logo.png?v=2023-08-19T00:59:29.119Z"
					width="120px"
					z-index="3"
					srcSet="https://smartuploads.quarkly.io/64d09596098f07001999d2f7/images/cuted_logo.png?v=2023-08-19T00%3A59%3A29.119Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/64d09596098f07001999d2f7/images/cuted_logo.png?v=2023-08-19T00%3A59%3A29.119Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/64d09596098f07001999d2f7/images/cuted_logo.png?v=2023-08-19T00%3A59%3A29.119Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/64d09596098f07001999d2f7/images/cuted_logo.png?v=2023-08-19T00%3A59%3A29.119Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/64d09596098f07001999d2f7/images/cuted_logo.png?v=2023-08-19T00%3A59%3A29.119Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/64d09596098f07001999d2f7/images/cuted_logo.png?v=2023-08-19T00%3A59%3A29.119Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/64d09596098f07001999d2f7/images/cuted_logo.png?v=2023-08-19T00%3A59%3A29.119Z&quality=85&w=3200 3200w"
					sizes="(max-width: 479px) 100vw,(max-width: 767px) 100vw,(max-width: 991px) 100vw,(max-width: 1199px) 100vw,100vw"
				/>
			</Link>
			<Components.BurgerMenu md-display="flex" md-align-items="center" md-justify-content="flex-end">
				<Override
					slot="menu"
					lg-transition="transform 400ms ease 0s"
					md-position="fixed"
					display="flex"
					md-top={0}
					padding="0px 0 0px 0"
					align-items="center"
					lg-transform="translateY(0px) translateX(0px)"
					md-width="100%"
					md-height="100%"
					justify-content="center"
					md-left={0}
				>
					<Override
						slot="item"
						text-transform="uppercase"
						text-align="center"
						padding="8px 20px 8px 20px"
						md-padding="16px 40px 16px 40px"
						display="inline-block"
					/>
					<Override slot="item-404" lg-display="none" display="none" />
					<Override slot="item-index" lg-display="none" display="none" />
					<Override
						slot="link"
						transition="opacity .15s ease 0s"
						hover-opacity="1"
						md-font="16px/24px sans-serif"
						color="--dark"
						font="--base"
						text-transform="initial"
						md-transition="opacity .15s ease 0s"
						md-active-opacity="1"
						text-decoration-line="initial"
						letter-spacing="0.5px"
						md-hover-opacity="1"
						opacity=".5"
						md-color="--dark"
						md-opacity=".5"
					/>
					<Override
						slot="link-active"
						md-opacity="1"
						md-cursor="default"
						opacity="1"
						color="--primary"
						cursor="default"
					/>
				</Override>
				<Override slot="icon,icon-close" category="md" icon={MdMenu} />
				<Override
					slot="icon"
					category="md"
					icon={MdMenu}
					size="36px"
					md-right="0px"
					md-position="relative"
				/>
				<Override
					slot="menu-open"
					md-display="flex"
					md-flex-direction="column"
					md-align-items="center"
					md-justify-content="center"
					md-top={0}
					md-bottom={0}
				/>
				<Override slot="icon-open" md-position="fixed" md-top="18px" md-right="18px" />
			</Components.BurgerMenu>
			<Link
				href="/contact"
				md-display="none"
				white-space="nowrap"
				color="--light"
				transition="transform --transitionDuration-fast --transitionTimingFunction-easeInOut 0s"
				font="--base"
				letter-spacing="0.5px"
				z-index="5"
				background="--color-primary"
				padding="8px 18px 8px 18px"
				border-radius="0px"
				hover-transform="translateY(-4px)"
				justify-self="end"
				text-decoration-line="initial"
			>
				Contact Us
			</Link>
		</Section>
		<Section lg-padding="25px 0 25px 0" quarkly-title="Hero" padding="25px 0 75px 0">
			<Stack>
				{"    "}
				<StackItem width="100%" display="flex">
					<Override
						slot="StackItemContent"
						align-items="center"
						justify-content="center"
						height="480px"
						background="radial-gradient(at center,--color-darkL2 23.3%,rgba(0,0,0,0) 82.4%),linear-gradient(180deg,rgba(155, 108, 252, 0.15) 0%,transparent 100%) 0 0 no-repeat,--color-darkL2"
						md-height="360px"
						flex-direction="column"
					/>
					{"        "}
					<Text
						text-align="center"
						padding="0px 16px 0px 16px"
						color="--light"
						font="--headline1"
						margin="16px 0px 0px 0px"
						sm-text-align="center"
						sm-width="80%"
					>
						404
					</Text>
					<Text
						margin="10px 0px 35px 0px"
						sm-text-align="center"
						sm-width="80%"
						opacity="0.7"
						text-align="center"
						padding="0px 16px 0px 16px"
						font="--lead"
						color="--light"
					>
						This page does not exist
					</Text>
					<Link
						href="/index"
						text-decoration-line="initial"
						color="--darkL2"
						background="--color-light"
						padding="12px 24px 12px 24px"
						letter-spacing="0.5px"
						hover-transform="translateY(-4px)"
						font="--base"
						transition="transform --transitionDuration-fast --transitionTimingFunction-easeInOut 0s"
					>
						Home Page
					</Link>
					{"    "}
				</StackItem>
				{"    "}
			</Stack>
		</Section>
		<Section padding="50px 0 50px 0" quarkly-title="Footer-3">
			<Override slot="SectionContent" sm-align-items="center" />
			<Box display="flex" flex-direction="column" align-items="center" margin="0px 0px 45px 0px" />
			<Text
				margin="0 0px 0 0px"
				font="--base"
				color="#5a5d64"
				text-align="center"
				sm-display="flex"
				sm-flex-wrap="wrap"
			>
				© 2021-2023 Dataleaks.us  . All rights reserved.
			</Text>
		</Section>

	</Theme>;
});